<template>
	<div class="test">
		<div class="page_1">
			<PageNav :show_num="4" />
			<div class="page_1_center_box">
				<div class="cen-text">
					Digital Taxation
				</div>
				<div class="cen-msg">
					Digital Taxation Solution Including Tax Control System(TCS)，Integrated Tax Administration System(ITAS)，e-Invoice System， Fiscal Registered Cashier(FRC)，and Tax Service Kiosk.
					By establishing a tax control system based on invoices, transaction information can be digitalized and collected in real-time, to improve tax certainty and efficiency, also reduce the work burden of taxpayers.
				</div>
			</div>
		</div>
		<div class="page_2">
			<div class="page_2_1" id="dplayer">
			</div>
		</div>

		<PageBot :show_num="4" />
		<el-backtop class="back_up" target="" :bottom="100" :visibility-height="620" :right="10">
			<svg class="icon icon_xiangshang" aria-hidden="true">
				<use xlink:href="#icon-xiangshang"></use>
			</svg>
		</el-backtop>
	</div>
</template>

<script>
	import PageNav from "@/components/PageNav.vue"
	import PageBot from "@/components/PageBottom.vue"
	import DPlayer from 'dplayer';
	import Swiper from "swiper";
	import "swiper/css/swiper.min.css";
	import {
		resetRem
	} from '@/all_js/all';
	import {
		clickEffect
	} from "@/all_js/click";
	export default {
		name: 'about',
		components: {
			PageBot,
			PageNav
		},
		created() {
			// resetRem(); // 根据1920尺寸设置rem，1rem=100px
			// clickEffect(); // 引入鼠标点击特效
		},
		watch: {

		},

		mounted() {
			this.init_video()
		},
		data() {
			return {
				avtive_nav: "",
				show_device_detail: false,
				device_detail: {},
				currentSwiper: null,
				currentSwipers: null,
				show_top: false,
				flag_status: true,
				navBarFixed: false,
				screenWidth: null,
				list_status: true,
				avtive_img: 0
			}
		},
		methods: {
			init_video() {
				// getVideo().then(res=>{
				// 	console.log(res)
				// })
				const dp = new DPlayer({
					container: document.getElementById('dplayer'),
					lang: "en", // 可选值：'en'、'zh-cn'、'zh-tw'
					loop: false, // 循环
					autoplay: false, // 自动播放
					live: false, // 直播形式
					video: {
						// url: require('../../assets/video/move.mp4'),
						url: this.api_base_url + "/offical-img/video/taxation.mp4",
						// url:  this.api_base_url + "/dev-platform-api/api/ows/media/video/play/sample.mp4",
						pic: require('../../assets/video/vb.png'),
					},
				});
			},
			go_index() {
				window.location.href = "/";
			},
			go_contact() {
				this.$router.push({
					path: '/contact',
					query: {
						id: '123456'
					},

				})
			},
		}
	}
</script>
<style scoped lang="less">
	* {
		margin: 0;
		padding: 0;
		// transition: all 1s; // 设置动画效果
	}

	li {
		list-style-type: none;
	}

	.page_1 {
		width: 100%;
		height: 100vh;
		max-height: 950px;
		object-fit: cover; // 保持图片缩放时候不变形
		background-image: url("../../assets/images/soluts/solut3_bg.png");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none; // 子元素无法被选中
	}


	.page_1_center_box {
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		color: #FFFFFF;

		.cen-text {
			margin-top: 30vh;
			margin-left: 15%;
			// background-color: aqua;
			font-size: 60px;
		}

		.cen-line {
			width: 72px;
			height: 4px;
			margin-top: 30px;
			margin-left: 15%;
			background-color: #FFFFFF;
		}

		.cen-content {
			margin-top: 10px;
			margin-left: 15%;
			font-size: 24px;
		}

		.cen-msg {
			width: 700px;
			margin-top: 10px;
			margin-left: 15%;
			font-size: 18px;
			line-height: 30px;
			// width: 500px;
		}


	}



	.back_up {
		height: 40px;
		width: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 30px;
		background-color: #49b1f5;

		.icon_xiangshang {
			height: 30px;
			width: 30px;
			border-radius: 30px;
		}
	}

	.back_up:hover {
		background-color: #49b1f5;
	}
</style>
<style scoped lang="less">
	// @media only screen and (min-width:1371px) {}

	.page_2 {
		width: 100%;
		min-width: 1360px;
		padding-top: 100px;
		padding-bottom: 100px;
		background: #FFFFFF;


		.page_2_1 {
			width: 1200px;
			height: 675px;
			background-color: aqua;
			margin: 0 auto;


		}
	}

</style>